<template>
  <div class="top_div">
    <!--侧边导航栏---->
    <div style="width: 11.8%;height: 100%;float: left">
      <el-menu default-active="1-4-1" class="el-menu-vertical-demo"  @select="handleSelect" >
        <el-menu-item index="受浴者浴前安全评估" style="height: 30%;text-align: left" >
          <i class="el-icon-s-comment"></i>
          <span slot="title">受浴者浴前安全评估</span>
        </el-menu-item>
        <el-menu-item index="受浴者浴后安全评估" style="height: 30%;text-align: left">
          <i class="el-icon-s-comment"></i>
          <span slot="title">受浴者浴后安全评估</span>
        </el-menu-item>
        <el-menu-item index="个人中心" style="height: 30%;text-align: left">
          <i class="el-icon-document"></i>
          <span slot="title">个人中心</span>
        </el-menu-item>
      </el-menu>
    </div>

    <!--具体内容----->
    <div  style="width: 88.2%;height: 100%;float: left;position: relative">
      <div style="width: 100%;height: 10%;background-color: cadetblue">
        <div style="width: 100%;height: 50%;background-color: cadetblue">
          <i class="el-icon-s-fold"
             style="font-size: 25px;padding-right: 1400px;padding-top: 10px"
          ></i>
          <el-dropdown style="float: right;" @command="handleCommand">
            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="d">个人中心</el-dropdown-item>
              <el-dropdown-item command="e" divided>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>


      </div>

      <div v-if="currentPage === '受浴者浴前安全评估'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <div class="table-wrapper" style="width: 50%">
          <el-form ref="form" :model="bathBeforeForm">
            <table>
              <tr>
                <th colspan="8"><strong>基本信息</strong></th>
              </tr>
              <tr>
                <td colspan="2" style="text-align: left">老人姓名:</td>
                <td colspan="2"><input v-model="bathBeforeForm.name" class="custom-input" name="name" required
                                       type="text"></td>
                <td colspan="2" style="text-align: left">性别：</td>
                <td colspan="2">
                  <label><input v-model="bathBeforeForm.gender" name="gender" required type="radio"
                                value="男">男</label>
                  <label><input v-model="bathBeforeForm.gender" name="gender" required type="radio"
                                value="女">女</label>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="text-align: left">老人年龄：</td>
                <td colspan="2"><input v-model="bathBeforeForm.age" class="custom-input" name="name" required
                                       type="text"></td>
                <td colspan="2" style="text-align: left">家庭地址：</td>
                <td colspan="2"><input v-model="bathBeforeForm.address" class="custom-input" name="address" required
                                       type="text"></td>
              </tr>
              <tr>
                <td colspan="2" style="text-align: left">联系人：</td>
                <td colspan="2"><input v-model="bathBeforeForm.FamilyName" class="custom-input" name="FamilyName"
                                       required type="text"></td>
                <td colspan="2" style="text-align: left">联系方式：</td>
                <td colspan="2"><input v-model="bathBeforeForm.FamilyPhone" class="custom-input" name="FamilyPhone"
                                       required type="text"></td>
              </tr>
              <tr>
                <th colspan="4">检查项目</th>
                <th colspan="4">测量值</th>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">血压（mmHg）</td>
                <td colspan="4"><input v-model="bathBeforeForm.blood_pressure" class="custom-input"
                                       name="blood_pressure"
                                       required type="text"></td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">血糖（mmol/L）</td>
                <td colspan="4"><input v-model="bathBeforeForm.blood_glucose" class="custom-input" name="blood_glucose"
                                       required type="text"></td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">心率（次/分）</td>
                <td colspan="4"><input v-model="bathBeforeForm.heart_rate" class="custom-input" name="heart_rate"
                                       required type="text"></td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">脉氧（%）</td>
                <td colspan="4"><input v-model="bathBeforeForm.spo2" class="custom-input" name="spo2" required
                                       type="text"></td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">体温（℃）</td>
                <td colspan="4"><input v-model="bathBeforeForm.temp" class="custom-input" name="temp" required
                                       type="text"></td>
              </tr>
              <tr>
                <th colspan="8">既往病史</th>
              </tr>
              <tr>
                <td colspan="8">
                  <textarea v-model="bathBeforeForm.anamnesis" cols="85" name="anamnesis" placeholder="此处为选填"
                            rows="10"></textarea>
                </td>
              </tr>
              <tr>
                <th colspan="8">评估结果</th>
              </tr>
              <tr>
                <td colspan="8">
                  <textarea v-model="bathBeforeForm.result" cols="85" name="result" placeholder="此处为选填"
                            rows="2"></textarea>
                </td>
              </tr>
              <tr>
                <th colspan="4"><strong>助浴前安全评价结果</strong></th>
                <th colspan="4">
                  <label><input v-model="bathBeforeForm.answer" name="answer" required type="radio" value="可受浴">可受浴</label>
                  <label><input v-model="bathBeforeForm.answer" name="answer" required type="radio" value="不可受浴">不可受浴</label>
                </th>
              </tr>
            </table>
          </el-form>
        </div>
        <el-button round style="width: 100px" type="primary" @click="submitBefore">提交</el-button>
        <el-button round style="width: 100px" @click="resetBefore">重置</el-button>

      </div>


      <div v-if="currentPage === '受浴者浴后安全评估'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <div class="table-wrapper"  style="width: 50%">
          <el-form ref="form" :model="bathAfterForm">
            <table>
              <tr>
                <th colspan="8"><strong>老人信息</strong></th>
              </tr>
              <tr>
                <td colspan="2" style="text-align: center">服务号：</td>
                <td colspan="2"><input v-model="bathAfterForm.serve_id" class="custom-input" name="serve_card"
                                       required type="text"></td>
                <td colspan="2" style="text-align: center">老人姓名：</td>
                <td colspan="2"><input v-model="bathAfterForm.name" class="custom-input" name="name" required
                                       type="text"></td>
              </tr>
              <tr>
                <td colspan="2" style="text-align: center">性别：</td>
                <td colspan="2">
                  <label><input v-model="bathAfterForm.gender" name="gender" required type="radio" value="男">男</label>
                  <label><input v-model="bathAfterForm.gender" name="gender" required type="radio" value="女">女</label>
                </td>
                <td colspan="2" style="text-align: center">年龄：</td>
                <td colspan="2"><input v-model="bathAfterForm.age" class="custom-input" name="age" required type="text">
                </td>
              </tr>
              <tr>
                <th colspan="4">检查项目</th>
                <th colspan="4">测量值</th>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">血压（mmHg）</td>
                <td colspan="4"><input v-model="bathAfterForm.blood_pressure" class="custom-input" name="blood_pressure"
                                       required type="text"></td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">血糖（mmol/L）</td>
                <td colspan="4"><input v-model="bathAfterForm.blood_glucose" class="custom-input" name="blood_glucose"
                                       required type="text"></td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">心率（次/分）</td>
                <td colspan="4"><input v-model="bathAfterForm.heart_rate" class="custom-input" name="heart_rate"
                                       required type="text"></td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">脉氧（%）</td>
                <td colspan="4"><input v-model="bathAfterForm.spo2" class="custom-input" name="spo2" required
                                       type="text"></td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">体温（℃）</td>
                <td colspan="4"><input v-model="bathAfterForm.temp" class="custom-input" name="temp" required
                                       type="text"></td>
              </tr>
              <tr>
                <th colspan="8"><strong>其他</strong></th>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">浴前评估时间：</td>
                <td colspan="4"><input v-model="bathAfterForm.before_time" class="custom-input" name="before_time"
                                       required type="text"></td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">浴后离开时间：</td>
                <td colspan="4"><input v-model="bathAfterForm.after_time" class="custom-input" name="after_time"
                                       required type="text"></td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">身体是否有不适</td>
                <td colspan="4">
                  <label><input v-model="bathAfterForm.malasize" name="malasize" required type="radio"
                                value="yes">是</label>
                  <label><input v-model="bathAfterForm.malasize" name="malasize" required type="radio"
                                value="no">否</label>
                </td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: center">浴中是否存在眩晕无力甚至跌倒</td>
                <td colspan="4">
                  <label><input v-model="bathAfterForm.vertigo" name="vertigo" required type="radio"
                                value="yes">是</label>
                  <label><input v-model="bathAfterForm.vertigo" name="vertigo" required type="radio"
                                value="no">否</label>
                </td>
              </tr>
              <tr>
                <th colspan="4"><strong>助浴后安全评价结果</strong></th>
                <th colspan="4">
                  <label><input v-model="bathAfterForm.result" name="result" required type="radio"
                                value="safe">安全</label>
                  <label><input v-model="bathAfterForm.result" name="result" required type="radio" value="unsafe">存在风险</label>
                </th>
              </tr>
            </table>
          </el-form>
        </div>
        <el-button round style="width: 100px" type="primary" @click="submitAfter">提交</el-button>
        <el-button round style="width: 100px" @click="resetAfter">重置</el-button>

      </div>

      <div v-if="currentPage === '个人中心'"
           style="width: 100%;height: 100%;
           margin: 10px auto">
        <div class="login-form" style="width: 35%; height: 550px;float: left">
          <el-form
              :model="ruleForm"
              status-icon
              ref="ruleForm"
              label-position="left"
              label-width="70px"
              class="login-from"
          >

            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" size="large"></el-avatar>
            <el-divider></el-divider>
            <el-form-item label="ID" label-width="80px" prop="id">
              <el-input v-model="ruleForm.id" :disabled="true" style="width: 300px"></el-input>
            </el-form-item>

            <el-form-item label="用户名" label-width="80px" prop="username">
              <el-input v-model="ruleForm.username" style="width: 300px"></el-input>
            </el-form-item>

            <el-form-item label="密码" label-width="80px" prop="password">
              <el-input
                  v-model="ruleForm.password"
                  autocomplete="off"
                  style="width: 300px"
                  type="password"
              ></el-input>
            </el-form-item>

            <el-form-item label="姓名" label-width="80px" prop="name">
              <el-input v-model="ruleForm.name" style="width: 300px"></el-input>
            </el-form-item>

            <el-form-item label="年龄" label-width="80px" prop="age">
              <el-input v-model="ruleForm.age" style="width: 300px"></el-input>
            </el-form-item>

            <el-form-item label="电话" label-width="80px" prop="phone">
              <el-input v-model="ruleForm.phone" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" label-width="80px" prop="identity">
              <el-input v-model="ruleForm.identity" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="所属车辆" label-width="80px">
              <el-input v-model="this.car_id" :disabled="true" style="width: 300px"></el-input>
            </el-form-item>
          </el-form>


          <el-button round type="primary" @click="updateData">更新数据</el-button>
        </div>

      </div>



      </div>
    </div>
</template>

<style scoped>
.top_div{
  width: 100%;
  height:100%;
  position: relative;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.service-form h2 {
  border-bottom: 2px solid #939393;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* 新增一个类，用于包裹表格的容器 */
.table-wrapper {
  width: 40%; /* 容器宽度为页面的40% */
  margin: 0 auto; /* 水平居中 */
  overflow: auto; /* 如果内容超出，可以滚动 */
  box-shadow: 0 2px 5px rgba(255, 0, 0, 0);
  border-radius: 5px;
  background: #fff;
  padding-top: 0px; /* 减小顶部内边距，从而减小间距 */
}

/* 确保表格宽度为100%，以便填满其容器 */
.table-wrapper table {
  width: 100%;
}

input[type="radio"] {
  margin-right: 5px;
}

/* 表格样式 */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

label {
  margin-right: 10px;
}

.custom-input {
  width: calc(100% - 22px);
  padding: 8px;
  margin: 5px 5px;
  border: 1px solid #000;
  border-radius: 4px;
  box-sizing: border-box;
}

form {
  margin-bottom: 40px;
}

.user-profile span {
  font-size: inherit; /* 设置为正常大小 */
}


.profile-form .field-group label {
  flex-shrink: 0; /* 不允许收缩 */
  margin-right: 20px; /* 标签与输入框之间的间距 */
  width: 120px; /* 标签宽度 */
  text-align: right; /* 标签右对齐 */
}

.profile-form .field-group input,
.profile-form .field-group select {
  width: 300px; /* 这里我将输入框和选择框的宽度设置为200px，你可以根据需要调整 */
  padding: 8px; /* 内边距 */
  margin-right: 10px; /* 输入框与下一个兄弟元素的间距 */
  border: 1px solid #ddd; /* 边框 */
  border-radius: 4px; /* 圆角边框 */
}


/* 按钮样式 */
.profile-form button {
  margin: 10px 0; /* 上下10px，左右根据需要调整 */
}
.login-form {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      currentPage:'受浴者浴前安全评估',
      selectedService: '受浴者浴前安全评估',
      //浴前结果表
      bathBeforeForm: {
        id: '',
        oldman_id: '',
        name: '',
        gender: '',
        age: '',
        address: '',
        FamilyName: '',
        FamilyPhone: '',
        blood_pressure: '',
        blood_glucose: '',
        heart_rate: '',
        spo2: '',
        temp: '',
        anamnesis: '',
        result: '',
        answer: ''
      },
      //洗浴后
      bathAfterForm: {
        id: '',
        serve_id: '',
        oldman_id: '',
        name: '',
        gender: '',
        time: '',
        blood_pressure: '',
        blood_glucose: '',
        heart_rate: '',
        spo2: '',
        temp: '',
        before_time: '',
        after_time: '',
        malasize: '',
        vertigo: '',
        result: ''
      },
      //个人中心
      car_id: '',
      ruleForm: {
        avator: "",
        id: "",
        username: "",
        password: "",
        name: "",
        age: "",
        phone: "",
        identity: '',
      },
      avatarFile: null, // 用户选择的头像文件
      isEditingProfile: false,
    }
  }, created() {
    this.getCar()
    this.fetchPrivateInfo();//展示个人资料
  },
  methods: {
    selectService(serviceType) {
      this.selectedService = serviceType
      if (serviceType === '受浴者浴前安全评估') {
      } else if (serviceType === '受浴者浴后安全评估') {

      }
    },
    //提交洗浴前评估表
    submitBefore() {
      axios({
        url: '/beforeRecords/add',               // 请求地址
        method: "post",
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id: null,
          oldman_id: null,
          name: this.bathBeforeForm.name,
          gender: this.bathBeforeForm.gender,
          age: this.bathBeforeForm.age,
          address: this.bathBeforeForm.address,
          FamilyPhone: this.bathBeforeForm.FamilyPhone,
          FamilyName: this.bathBeforeForm.FamilyName,
          blood_pressure: this.bathBeforeForm.blood_pressure,
          blood_glucose: this.bathBeforeForm.blood_glucose,
          heart_rate: this.bathBeforeForm.heart_rate,
          spo2: this.bathBeforeForm.spo2,
          temp: this.bathBeforeForm.temp,
          anamnesis: this.bathBeforeForm.anamnesis,
          result: this.bathBeforeForm.result,
          answer: this.bathBeforeForm.answer,
        }),// 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        console.log(res)
        if (res.data.code === '200') {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //重置前表
    resetBefore() {
      // 重置浴前评估表单数据
      this.bathBeforeForm = {
        oldman_id: '',
        name: '',
        gender: '',
        age: '',
        address: '',
        FamilyName: '',
        FamilyPhone: '',
        blood_pressure: '',
        blood_glucose: '',
        heart_rate: '',
        spo2: '',
        temp: '',
        anamnesis: '',
        result: '',
        answer: ''
      }
    },
    //提交洗浴后安全表
    submitAfter() {
      axios({
        url: '/afterRecords/add',               // 请求地址
        method: "post",
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id: null,
          oldman_id:null ,
          serve_id: this.bathAfterForm.serve_id,
          time: null,
          name: this.bathAfterForm.name,
          gender: this.bathAfterForm.gender,
          age: this.bathAfterForm.age,
          blood_pressure: this.bathAfterForm.blood_pressure,
          blood_glucose: this.bathAfterForm.blood_glucose,
          heart_rate: this.bathAfterForm.heart_rate,
          spo2: this.bathAfterForm.spo2,
          before_time: this.bathAfterForm.before_time,
          after_time: this.bathAfterForm.after_time,
          temp: this.bathAfterForm.temp,
          malasize: this.bathAfterForm.malasize,
          vertigo: this.bathAfterForm.vertigo,
          result: this.bathAfterForm.result,
        }),// 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        console.log(res)
        if (res.data.code === '200') {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //重置后
    resetAfter() {
      // 重置浴后评估表单数据
      this.bathAfterForm = {
        id: '',
        serve_id: '',
        oldman_id: '',
        name: '',
        gender: '',
        time: '',
        blood_pressure: '',
        blood_glucose: '',
        heart_rate: '',
        spo2: '',
        temp: '',
        before_time: '',
        after_time: '',
        malasize: '',
        vertigo: '',
        result: ''
      }
    },
    logout() {
      // 清除身份验证状态
      sessionStorage.removeItem('userInfo');
      sessionStorage.removeItem('token');

      // 重定向到登录页面
      this.$router.push('/')
    },
    fetchPrivateInfo() {
      axios({
        url: "/nurse/selectAll",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id: sessionStorage.getItem('user_id')
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.ruleForm = res.data.data[0]
        console.log(res);
      });
    },
    getCar() {
      axios({
        url: `/nurse/selectByIdAdmin/${sessionStorage.getItem('user_id')}`,               // 请求地址
        method: "get",                       // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        if (res.data.data !== null) {
          this.car_id = res.data.data.car_id
        }
        console.log(res);
      });
    },
    //展
    updateData() {
      // 发送更新请求到后端API
      axios({
        url: "/nurse/update",               // 请求地址
        method: "put",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id: this.ruleForm.id,
          username: this.ruleForm.username,
          password: this.ruleForm.password,
          name: this.ruleForm.name,
          gender: this.ruleForm.gender,
          phone: this.ruleForm.phone,
          age: this.ruleForm.age,
          idcard: this.ruleForm.idcard
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        console.log(res)
        if (res.data.code === "200") {
          alert('操作成功: 数据' + res.data.msg);
        } else {
          alert('操作失败: 数据' + res.data.msg);
        }
      });
    },
    handleSelect(key){
      this.currentPage = key
    },
    handleCommand(command){
      if(command === 'e'){
        this.logout()
      }else{
        this.currentPage = '个人中心'
      }
    }
  },

}
</script>
