import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';// 引入element-ui
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.prototype.$http = axios

// axios.defaults.baseURL = "http://localhost:7000"
axios.defaults.baseURL = "/prod-api"
axios.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  const token = sessionStorage.getItem('token');
  if (token) {
    config.headers['Token'] = ` ${token}`;
  }
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});


new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
