import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '../views/Admin.vue'
import log from '../views/userLog.vue'
import register from '../views/register.vue'
import Oldman from '../views/Oldman.vue'
import Nurse from '../views/Nurse.vue'
import Driver from '../views/Driver.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'log',
    component: log,
    meta: { requiresAuth: false }, // 登录页面不需要认证
  },
  {
    path: "/register",
    component: register,
    meta: { requiresAuth: false }, // 注册页面不需要认证
  },
  {
    path: "/Oldman",
    component: Oldman,
    meta: { requiresAuth: true, role: 'OLDMAN' },
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
    meta: { requiresAuth: true, role: 'ADMIN' },
  },
  {
    path: "/Nurse",
    component: Nurse,
    meta: { requiresAuth: true, role: 'NURSE' },
  },{
    path: "/Driver",
    component: Driver,
    meta: { requiresAuth: true, role: 'DRIVER' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 从localStorage获取token和userinfo
  const token = sessionStorage.getItem('token');
  const userinfo =sessionStorage.getItem('userinfo');

  // 如果访问的路由需要认证
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果用户未认证
    if (token === null) {
      next('/login'); // 重定向到登录页
    } else {
      // 如果用户已认证，检查角色是否匹配请求的路由
      const role = userinfo;
      console.log(role);
      if (to.matched.some(record => record.meta.role === role)) {
        next(); // 角色匹配，允许访问
      } else {
        next('/login'); // 角色不匹配，重定向到403禁止访问页面
      }
    }
  } else {
    // 如果路由不需要认证，直接允许访问
    next();
  }
});


export default router
