<template>
  <div class="top_div">
    <!--侧边导航栏---->
    <div style="width: 11.8%;height: 100%;float: left">
      <el-menu default-active="1-4-1" class="el-menu-vertical-demo"  @select="handleSelect" >
        <el-menu-item index="司机信息" style="height: 30%;text-align: left" >
          <i class="el-icon-s-comment"></i>
          <span slot="title">司机信息</span>
        </el-menu-item>
        <el-menu-item index="车辆信息" style="height: 30%;text-align: left">
          <i class="el-icon-s-comment"></i>
          <span slot="title">车辆信息</span>
        </el-menu-item>
        <el-menu-item index="医护人员信息" style="height: 30%;text-align: left">
          <i class="el-icon-document"></i>
          <span slot="title">医护人员信息</span>
        </el-menu-item>
        <el-menu-item index="预约信息" style="height: 30%;text-align: left">
          <i class="el-icon-document"></i>
          <span slot="title">预约信息</span>
        </el-menu-item>
        <el-menu-item index="浴前评估表" style="height: 30%;text-align: left">
          <i class="el-icon-edit"></i>
          <span slot="title">浴前评估表</span>
        </el-menu-item>
        <el-menu-item index="洗浴记录" style="height: 30%;text-align: left">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">洗浴记录</span>
        </el-menu-item>
        <el-menu-item index="浴后评估表" style="height: 30%;text-align: left">
          <i class="el-icon-edit"></i>
          <span slot="title">浴后评估表</span>
        </el-menu-item>
        <el-menu-item index="管理中心" style="height: 50%;text-align: left">
          <i class="el-icon-location-information"></i>
          <span slot="title">管理中心</span>
        </el-menu-item>
        <el-menu-item index="个人中心" style="height: 50%;text-align: left">
          <i class="el-icon-location-information"></i>
          <span slot="title">个人中心</span>
        </el-menu-item>
      </el-menu>
    </div>

    <!--具体内容----->
    <div  style="width: 88.2%;height: 100%;float: left;position: relative">
      <div style="width: 100%;height: 10%;background-color: cadetblue">
        <div style="width: 100%;height: 50%;background-color: cadetblue">
          <i class="el-icon-s-fold"
             style="font-size: 25px;padding-right: 1400px;padding-top: 10px"
             ></i>
          <el-dropdown style="float: right;" @command="handleCommand">
            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="d">个人中心</el-dropdown-item>
              <el-dropdown-item command="e" divided>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>


      </div>

      <div v-if="currentPage === '医护人员信息'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <div style="width: 92%;height: 100%;float: left">
          <div style="float: left">
            <el-input v-model="searchText" placeholder="请输入" style="width: 200px;" />
            <el-select v-model="value" style="width: 140px">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-button  class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch()">
              搜索
            </el-button>
            <!--          <el-button class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">
                        添加
                      </el-button>-->
          </div>

          <div style="padding-top: 60px">
            <el-table
                :data="tableData"
                style="width: 100%;border: 1px black solid"
            >
              <el-table-column label="序号" prop="id"  align="center" width="80" >
              </el-table-column>
              <el-table-column label="用户名" prop="username" width="150px" align="center">
              </el-table-column>
              <el-table-column label="密码" prop="password" width="150px">
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="110px" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="电话" width="150px">
              </el-table-column>
              <el-table-column  label="年龄" prop="age" align="center" width="95"></el-table-column>
              <el-table-column label="身份证号" prop="identity" width="200">
              </el-table-column>
                <el-table-column label="护理证等级" prop="level" width="100" >
                </el-table-column>
                <el-table-column label="所属车牌号" prop="car_id" width="100" >
                </el-table-column>
              <el-table-column label="操作" align="center" width="230" class-name="small-padding fixed-width">
                <template slot-scope="{row,$index}">
                  <el-button type="primary" size="mini" @click="handleClick(row)">
                    编辑
                  </el-button>
                  <el-button  size="mini" type="danger" @click="deleteByRow(row.id)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-dialog  :visible.sync="editDialogVisible" width="30%">
              <el-form :model="editForm">
                <el-form-item label="序号" label-width="90px">
                  <el-input v-model="editForm.id" size="small"></el-input>
                </el-form-item>
                <el-form-item label="用户名" label-width="90px">
                  <el-input v-model="editForm.username"></el-input>
                </el-form-item>
                <el-form-item label="密码" label-width="90px">
                  <el-input v-model="editForm.password"></el-input>
                </el-form-item>
                <el-form-item label="姓名" label-width="90px">
                  <el-input v-model="editForm.name"></el-input>
                </el-form-item>
                <el-form-item label="电话" label-width="90px">
                  <el-input v-model="editForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="年龄" label-width="90px">
                  <el-input v-model="editForm.age"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" label-width="90px">
                  <el-input v-model="editForm.identity"></el-input>
                </el-form-item>
                <el-form-item label="护理证级别" label-width="90px">
                  <el-input v-model="editForm.level"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
      <el-button @click="editDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="handleSubmitEdit">提交</el-button>
          </span>
            </el-dialog>
          </div>


        </div>


      </div>


      <div v-if="currentPage === '车辆信息'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <div style="width: 85%;height: 100%;float: left">
          <div style="float: left">
            <el-input v-model="searchTextCar" placeholder="请输入" style="width: 200px;" />
            <el-button  class="filter-item" type="primary" icon="el-icon-search" @click="fetchCar()">
              搜索
            </el-button>
            <!--          <el-button class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">
                        添加
                      </el-button>-->
          </div>

          <div style="padding-top: 60px">
            <el-table
                :data="tableDataCar"
                border
                style="width: 100%;border: 1px black solid"
            >
              <el-table-column label="车牌号" prop="car_id" width="150"></el-table-column>
              <el-table-column label="车辆状态" prop="state" width="120"></el-table-column>
              <el-table-column label="所属省份" prop="province_id" width="120"></el-table-column>
              <el-table-column prop="city_id" label="所属城市" width="120"></el-table-column>
              <el-table-column prop="district_id" label="所属区域" width="120"></el-table-column>
              <el-table-column label="所属街道" prop="street_id" width="120"></el-table-column>
              <el-table-column label="司机id" prop="driver_id" width="120"></el-table-column>
              <el-table-column label="随车护士" prop="nurse_id" width="120" ></el-table-column>
              <el-table-column label="操作" align="center" width="230" class-name="small-padding fixed-width">
                <template slot-scope="{row,$index}">
                  <el-button type="primary" size="mini" @click="handleClickCar(row)">
                    编辑
                  </el-button>
                  <el-button  size="mini" type="danger" @click="deleteByRowCar(row.id)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-dialog  :visible.sync="editDialogVisibleCar" width="30%">
              <el-form :model="editFormCar">
                <el-form-item label="车牌号" label-width="90px">
                  <el-input v-model="editFormCar.car_id" size="small"></el-input>
                </el-form-item>
                <el-form-item label="车辆状态" label-width="90px">
                  <el-input v-model="editFormCar.state"></el-input>
                </el-form-item>
                <el-form-item label="所属省份" label-width="90px">
                  <el-input v-model="editFormCar.province_id"></el-input>
                </el-form-item>
                <el-form-item label="所属城市" label-width="90px">
                  <el-input v-model="editFormCar.city_id"></el-input>
                </el-form-item>
                <el-form-item label="所属区域" label-width="90px">
                  <el-input v-model="editFormCar.district_id"></el-input>
                </el-form-item>
                <el-form-item label="所属街道" label-width="90px">
                  <el-input v-model="editFormCar.street_id"></el-input>
                </el-form-item>
                <el-form-item label="司机id" label-width="90px">
                  <el-input v-model="editFormCar.driver_id"></el-input>
                </el-form-item>
                <el-form-item label="随车护士id" label-width="90px">
                  <el-input v-model="editFormCar.nurse_id"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
      <el-button @click="editDialogVisibleCar = false">取消</el-button>
      <el-button type="primary" @click="handleSubmitEditCar">提交</el-button>
          </span>
            </el-dialog>
          </div>


        </div>


      </div>

      <div v-if="currentPage === '司机信息'"
           style="width: 100%;height:100%;
           margin: 10px auto">

        <div style="width: 85%;height: 100%;float: left">
          <div style="float: left">
            <el-input v-model="searchText_driver" placeholder="请输入" style="width: 200px;" />
            <el-select v-model="value" style="width: 140px">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-button  class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch_Driver()">
              搜索
            </el-button>
            <!--          <el-button class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">
                        添加
                      </el-button>-->
          </div>

          <div style="padding-top: 60px">
            <el-table
                :data="tableData_driver"
                border
                style="width: 100%;border: 1px black solid"
            >
              <el-table-column label="序号" prop="id"  align="center" width="80" >
              </el-table-column>
              <el-table-column label="用户名" prop="username" width="150px" align="center">
              </el-table-column>
              <el-table-column label="密码" prop="password" width="150px">
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="110px" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="电话" width="150px">
              </el-table-column>
              <el-table-column  label="年龄" prop="age" align="center" width="95">
              </el-table-column>
              <el-table-column label="车牌号" prop="car_id"  width="100">
              </el-table-column>
              <el-table-column
                  label="身份证号"
                  prop="identity"
                  width="200">
              </el-table-column>
              <el-table-column label="操作" align="center" width="230" class-name="small-padding fixed-width">
                <template slot-scope="{row,$index}">
                  <el-button type="primary" size="mini" @click="handleClick_Driver(row)">
                    编辑
                  </el-button>
                  <el-button  size="mini" type="danger" @click="deleteByRow_Driver(row.id)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-dialog  :visible.sync="editDialogVisible_Driver" width="30%">
              <el-form :model="editForm_Driver">
                <el-form-item label="ID" label-width="90px">
                  <el-input v-model="editForm_Driver.id" size="small"></el-input>
                </el-form-item>
                <el-form-item label="用户名" label-width="90px">
                  <el-input v-model="editForm_Driver.username"></el-input>
                </el-form-item>
                <el-form-item label="密码" label-width="90px">
                  <el-input v-model="editForm_Driver.password"></el-input>
                </el-form-item>
                <el-form-item label="姓名" label-width="90px">
                  <el-input v-model="editForm_Driver.name"></el-input>
                </el-form-item>
                <el-form-item label="电话" label-width="90px">
                  <el-input v-model="editForm_Driver.phone"></el-input>
                </el-form-item>
                <el-form-item label="年龄" label-width="90px">
                  <el-input v-model="editForm_Driver.age"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" label-width="90px">
                  <el-input v-model="editForm_Driver.identity"></el-input>
                </el-form-item>
                <el-form-item label="所属车牌号" label-width="90px">
                  <el-input v-model="editForm_Driver.car_id"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
              <el-button @click="editDialogVisible_Driver = false">取消</el-button>
              <el-button type="primary" @click="handleSubmitEdit_Driver">提交</el-button>
            </span>
            </el-dialog>
          </div>


        </div>


      </div>

      <div v-if="currentPage === '预约信息'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <div style="width: 72%;height: 100%;float: left">

          <div style="padding-top: 30px">
            <el-table
                :data="tableData_pre"
                border
                style="width: 100%;border: 1px black solid"
            >
              <el-table-column
                  label="活动序号"
                  prop="id"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="province"
                  label="所在省"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="city"
                  label="所在市"
                  width="120">
              </el-table-column>
              <el-table-column
                  label="所在区"
                  prop="district"
                  width="120">
              </el-table-column>
              <el-table-column
                  label="所在街"
                  prop="street"
                  width="120">
              </el-table-column>
              <el-table-column
                  label="审核状态"
                  prop="status"
                  width="120">
              </el-table-column>
              <el-table-column
                  label="车牌号"
                  prop="car_id"
                  width="120">
              </el-table-column>
              <el-table-column label="操作" align="center" width="230" class-name="small-padding fixed-width">
                <template slot-scope="{row,$index}">
                  <el-button type="primary" size="mini" @click="handleClick_pre(row)">
                    审核
                  </el-button>
                  <el-button  size="mini" type="primary" @click="handleClick_pre_2(row)">
                    编辑
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-dialog
                title="审核"
                :visible.sync="dialogVisible"
                width="30%"
            >
              <el-form :model="currentRowData">
                <div>是否通过当前审核项目？</div>
              </el-form>
              <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="handleApprove">通过</el-button>
              <el-button type="danger" @click="handleReject">不通过</el-button>
              <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
            </el-dialog>

            <!--- 编辑模态框 -->
            <el-dialog
                title="编辑"
                :visible.sync="editDialogVisible_Edit"
                width="30%"
            >
              <el-form :model="editForm_edi">
                <el-form-item label="省" label-width="80px">
                  <el-input v-model="editForm_edi.province"></el-input>
                </el-form-item>
                <el-form-item label="市" label-width="80px">
                  <el-input v-model="editForm_edi.city"></el-input>
                </el-form-item>
                <el-form-item label="区" label-width="80px">
                  <el-input v-model="editForm_edi.district"></el-input>
                </el-form-item>
                <el-form-item label="街" label-width="80px">
                  <el-input v-model="editForm_edi.street"></el-input>
                </el-form-item>
                <el-form-item label="车牌号" label-width="80px">
                  <el-input v-model="editForm_edi.car_id"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
              <el-button @click="editDialogVisible_Edit= false">取消</el-button>
              <el-button type="primary" @click="updateDatabase">更新</el-button>
            </span>
            </el-dialog>

          </div>


        </div>


      </div>

      <div v-if="currentPage === '浴前评估表'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <div style="width: 98%;height: 100%;float: left">

          <div style="padding-top: 30px">
            <el-table
                :data="tableDateBefore"
                style="width: 100%;border: 1px black solid">
              <el-table-column
                  prop="id"
                  label="序号"
                  width="80">
              </el-table-column>
              <el-table-column
                  prop="oldman_id"
                  label="老人序号"
                  width="80">
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="地址"
                  width="300">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="老人姓名"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="gender"
                  label="性别"
                  width="80">
              </el-table-column>
              <el-table-column
                  prop="age"
                  label="年龄"
                  width="80">
              </el-table-column>
              <el-table-column
                  prop="blood_pressure"
                  label="血压"
                  width="80">
              </el-table-column>
              <el-table-column
                  prop="blood_glucose"
                  label="血糖"
                  width="80">
              </el-table-column>
              <el-table-column
                  prop="heart_rate"
                  label="心率"
                  width="80">
              </el-table-column>
              <el-table-column
                  prop="spo2"
                  label="脉氧"
                  width="80">
              </el-table-column>
              <el-table-column
                  prop="temp"
                  label="体温"
                  width="80">
              </el-table-column>
              <el-table-column
                  prop="anamnesis"
                  label="既往病史"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="result"
                  label="评估结果"
                  width="150">
              </el-table-column>
            </el-table>
          </div>


        </div>


      </div>

      <div v-if="currentPage === '洗浴记录'"
           style="width: 100%;height: 100%;
           float: left">

        <div style="width: 60%;height: 100%;float: left">

          <div style="padding-top: 30px">
            <el-table
                :data="tableDateRecord"
                border
                style="width: 100%;border: 1px black solid"
            >
              <el-table-column
                  prop="act_id"
                  label="活动号"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="serve_id"
                  label="洗浴号"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="老人姓名"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="gender"
                  label="老人性别"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="date"
                  label="活动时间"
                  width="140">
              </el-table-column>
              <el-table-column label="操作" align="center" width="100" class-name="small-padding fixed-width">
                <template slot-scope="{row,$index}">
                  <el-button type="primary" size="mini" @click="viewView(row)">
                    查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-dialog
                :visible.sync="ViewVisable"
                width="30%"
                style="height: 65%;">

              <el-card class="box-card" >
                <div slot="header" class="clearfix">
                  <span>监测数据</span>
                </div>
                <div class="text item">
                  <el-form ref="DataAvg" :model="DataAvg" >
                    <el-form-item label="CO" label-width="60px">
                      <el-input v-model="DataAvg.co" style="width: 200px" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="CO2" label-width="60px">
                      <el-input v-model="DataAvg.co2" style="width: 200px" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="温度" label-width="60px">
                      <el-input v-model="DataAvg.tem" style="width: 200px" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="湿度" label-width="60px">
                      <el-input v-model="DataAvg.hum" style="width: 200px" :disabled="true"></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </el-card>

            </el-dialog>
          </div>


        </div>


      </div>

      <div v-if="currentPage === '浴后评估表'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <div style="width: 85%;height: 100%;float: left">

          <div style="padding-top: 30px">
            <el-table
                :data="tableDateAfter"
                border
                style="width: 100%;border: 1px black solid">
              <el-table-column
                  fixed
                  prop="id"
                  label="洗浴号"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="serve_id"
                  label="服务号"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="oldman_id"
                  label="老人序号"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="gender"
                  label="性别"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="姓名"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="time"
                  label="洗浴时间"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="blood_pressure"
                  label="血压"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="blood_glucose"
                  label="血糖"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="heart_rate"
                  label="心率"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="spo2"
                  label="脉氧"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="temp"
                  label="体温"
                  width="120">
              </el-table-column>
            </el-table>
          </div>


        </div>


      </div>

      <div v-if="currentPage === '管理中心'"
           style="width: 100%;height: 100%;
           margin: 10px auto;">

        <div style="width: 62%;height: 100%;float: left">
          <div style="float: left">
            <el-select @change="cityInfo" v-model="province" placeholder="请选择省份" style="width: 150px">
            <el-option
                v-for="item in provinceAll"
                :key="item.province"
                :label="item.province"
                :value="item.province">
            </el-option>
            </el-select>

            <!--下拉框-市级---->

            <el-select @change="areaInfo" v-model="city" placeholder="请选择城市" style="width: 150px">
              <el-option
                  v-for="item in cityAll"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>


            <!--下拉框-区级---->

            <el-select @change="streetInfo" v-model="area" placeholder="请选择区域" style="width: 150px">
              <el-option
                  v-for="item in AreaAll"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>


            <!---下拉框-街道---->
            <el-select  @change="AcArea()" v-model="street" placeholder="请选择街道" style="width: 140px">
              <el-option
                  v-for="item in streetAll"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>

          <div style="padding-top: 60px">
            <el-table
                :data="tableData_Area"
                border
                style="width: 100%;border: 1px black solid"
            >
              <el-table-column
                  fixed
                  prop="province"
                  label="省"
                  width="200">
              </el-table-column>
              <el-table-column
                  prop="city"
                  label="市"
                  width="200">
              </el-table-column>
              <el-table-column
                  prop="district"
                  label="区"
                  width="200">
              </el-table-column>
              <el-table-column
                  prop="street"
                  label="街"
                  width="200">
              </el-table-column>
              <el-table-column label="操作" align="center" width="100" class-name="small-padding fixed-width">
                <template slot-scope="{row,$index}">
                  <el-button  size="mini" type="danger" @click="deleteByRow_Area(row.id)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>


        </div>


      </div>
      <div v-if="currentPage === '个人中心'"
           style="width: 100%;height: 100%;
           margin: 10px auto">
        <div class="login-form" style="width: 35%; height: 550px;float: left">
          <el-form
              :model="ruleForm"
              status-icon
              ref="ruleForm"
              label-position="left"
              label-width="70px"
              class="login-from"
          >
            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" size="large"></el-avatar>
            <el-divider></el-divider>
            <el-form-item label="序号" prop="id" label-width="60px">
              <el-input v-model="ruleForm.id" style="width: 300px" :disabled="true" ></el-input>
            </el-form-item>

            <el-form-item label="用户名" prop="username" label-width="60px">
              <el-input v-model="ruleForm.username" style="width: 300px"></el-input>
            </el-form-item>

            <el-form-item label="密码" prop="password" label-width="60px">
              <el-input
                  type="password"
                  v-model="ruleForm.password"
                  autocomplete="off"
                  style="width: 300px"
              ></el-input>
            </el-form-item>

            <el-form-item label="姓名" prop="name" label-width="60px">
              <el-input v-model="ruleForm.name" style="width: 300px" ></el-input>
            </el-form-item>

            <el-form-item label="邮箱" prop="email" label-width="60px">
              <el-input v-model="ruleForm.email" style="width: 300px"></el-input>
            </el-form-item>


            <el-form-item label="电话" prop="phone" label-width="60px">
              <el-input v-model="ruleForm.phone" style="width: 300px"></el-input>
            </el-form-item>
          </el-form>


          <el-button type="primary" round @click="updateData">更新数据</el-button><br/>
        </div>
    </div>

  </div>
  </div>
</template>

<style scoped>
.top_div{
  width: 100%;
  height:100%;
  position: relative;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.login-form {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>

<script>
import axios from "axios";

export default {
  data () {
    return {
      DataAvg:{
        co:'',
        co2:'',
        hum:'',
        tem:'',
      },
      //车辆信息
      searchTextCar:'',
      tableDataCar:[],
      editDialogVisibleCar:false,
      editFormCar:{
        car_id:'',
        state:'',
        province_id:'',
        city_id:'',
        district_id:'',
        street_id:'',
        driver_id:'',
        nurse_id:''
      },
      //折线图的模态框
      ViewVisable : false,
      //洗浴记录
      editDialogVisible_Edit:false,
      tableDateRecord:[],
      //审核
      // 模态框是否可见
      dialogVisible:false,
      editDialogVisible_edi:false,
      // 当前点击审核行的数据
      currentRowData: {},
      editForm_edi:{},
      //查询下拉框
      //预约
      tableData_pre:[],
      //个人中心
      ruleForm: {
        id:"",
        username: "",
        password: "",
        name:"",
        email:"",
        phone:""
      },
      //区域
      tableData_Area: [],
      province:'',
      provinceAll:[],
      city:'',
      cityAll:[],
      area:'',
      AreaAll:[],
      street:'',
      streetAll:[],
      Area:{
        province:null,
        city:null,
        area:null,
        street:null,
      },
      //司机
      searchText_driver:"",
      Driver:{
        id:null,
        username:null,
        name:null,
      },
      tableData_driver: [],
      editForm_Driver: {},
      editDialogVisible_Driver: false,
      //医护人员模块
      searchText:"",      //医护人员的搜索框
      options: [{
        value: 'id',
        label: 'id'
      }, {
        value: 'name',
        label: '姓名'
      }, {
        value: 'username',
        label: '用户名'
      }],
      value: '',
      editDialogVisible: false, // 控制模态框显示
      editForm: {}, // 编辑表单的数据模型
      tableData: [],
      tableDateAfter: [],
      tableDateBefore: [],
      isLoading: true, // 控制加载指示器显示的布尔值
      currentPage: '司机信息', // 当前选中的页面
      isEditingProfile: false,
      //护士模块
      Nurse:{
        id:null,
        username:null,
        name:null,
        car_id:null,
      }
    }
  },created () {
    this.fetchCar()
    this.fetchNurse();    //展示护士表
    this.fetchDriver();  //展示司机表
    this.fetchRecords();  //展示洗浴记录
    this.initializeForm(); //个人中心页面的初始化
    this.fetchPre();     //预约
    this.fetchBefore();   //展示浴前表
    this.fetchAfter();    //展示浴后表
    this.fetchOptions_province(); //查询所有的省份
    this.fetchAc()
  },
  methods: {
    //展示车辆信息
    fetchCar(){
      axios({
        url: "/vehicle/selectAll",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data:JSON.stringify({
          car_id:this.searchTextCar
        })
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableDataCar = res.data.data;
        console.log(res);
      })
    },
    //车辆的模态框
    handleClickCar(row){
      // 显示模态框并填充当前行数据
      this.editFormCar = { ...row };
      this.editDialogVisibleCar = true;
    },
    deleteByRowCar(id){
      // 确认删除的提示
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 发送删除请求到后端
        axios.delete(`/vehicle/delete/${this.editFormCar.car_id}`).then((response) => {
          // 根据响应判断删除成功
          if (response.data.code === "200" || response.data.success) {
            this.fetchCar()
            // 删除成功
            this.$message({
              type: 'success',
              message: '删除成功'
            });
          } else {
            // 删除失败
            this.$message({
              type: 'error',
              message: '删除失败'
            });
          }
        }).catch(error => {
          console.error('删除请求失败:', error);
          this.$message({
            type: 'error',
            message: '删除请求失败'
          });
        });
      }).catch(() => {
        // 用户取消删除
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSubmitEditCar(){
      axios.put(`/vehicle/update`, this.editFormCar)
          .then(response => {
            // 表单提交成功后的逻辑
            this.$message.success('更新成功！');
            // 更新表格数据
            this.fetchCar()
            // 关闭模态框
            this.editDialogVisibleCar = false;
          })
          .catch(error => {
            // 错误处理逻辑
            this.$message.error('更新失败，请稍后重试！');
            console.error('更新请求失败:', error);
          });
    },
    //折线图
    viewView(row){
      console.log(row.act_id)
      // 显示模态框并填充当前行数据
      this.ViewVisable= true;

      Promise.all([
        this.getTem(row.act_id),
        this.getHum(row.act_id),
        this.getCo(row.act_id),
        this.getCo2(row.act_id)
      ]).then(([data1, data2, data3, data4]) => {
        this.DataAvg.tem=data1
        this.DataAvg.hum=data2
        this.DataAvg.co=data3
        this.DataAvg.co2=data4
      }).catch(error => {
        console.error('请求失败:', error);
        // 处理请求错误
      });
    },

    getTem(id){
      return this.$http.get(`/recordtem/AVGtem/${id}`).then(response => {
        return response.data.data;
      });
    },
    getHum(id){
      return this.$http.get(`/recordhum/AVGhum/${id}`).then(response => {
        return response.data.data;
      });
    },
    getCo(id){
      return this.$http.get(`/recordco/AVGco/${id}`).then(response => {
        return response.data.data;
      });
    },
    getCo2(id){
      return this.$http.get(`/recordco2/AVGco2/${id}`).then(response => {
        return response.data.data;
      });
    },
    //展示所有的区域
    fetchAc(){
      this.$http({
        url: '/area/selectByAll',               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data:JSON.stringify({
          province:this.Area.province,
          city:this.Area.city,
          district:this.Area.area,
          street:this.Area.street,
        })
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableData_Area = res.data.data;
        console.log(res);
      })
    },
    //查询所有的省份----首页
    fetchOptions_province() {
      this.$http({     // axios 向后端发起请求
        url: "/province/selectAllP",  // 请求地址
        method: "post",             // 请求方法
        headers: {                  // 请求头
          "Content-Type": "application/json",
          'TOKEN ': ` ${sessionStorage.getItem('token')}`
        },
        data: JSON.stringify({
          province_id:1,
          province:'江苏',
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.provinceAll = res.data.data;
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    //首页
    cityInfo() {
      this.$http({     // axios 向后端发起请求
        url: `city/selectPforC/${this.province}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.Area.city=null;
        this.Area.area=null;
        this.Area.street=null;
        this.city=null;
        this.area=null;
        this.street=null;
        this.cityAll = res.data.data;
        this.Area.province=this.province;
        this.fetchAc();
      }).catch(error => {
        console.error("请求错误:", error);
      });
    }, areaInfo() {
      this.$http({     // axios 向后端发起请求
        url: `/district/selectCforD/${this.city}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.Area.area=null;
        this.Area.street=null;
        this.area=null;
        this.street=null;
        this.AreaAll = res.data.data;
        this.Area.city=this.city;
        this.fetchAc();
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },streetInfo() {
      this.$http({     // axios 向后端发起请求
        url: `/street/selectDforS/${this.area}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.streetAll = res.data.data;
        this.Area.street=null;
        console.log(res.data.data);
        this.Area.area=this.area;
        this.fetchAc();
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    AcArea(){
      this.Area.street = this.street;
      this.fetchAc();
    },
    handleSearch(){
      if(this.value==="id") {
        this.Nurse.id = this.searchText
        this.Nurse.name=null
        this.Nurse.username=null
      }else if(this.value==="name"){
        this.Nurse.name=this.searchText
        this.Nurse.id=null
        this.Nurse.username=null
      }
      else if(this.value==="username"){
        this.Nurse.username = this.searchText
        this.Nurse.name=null
        this.Nurse.id=null
      }
      this.fetchNurse()
    },
    handleSearch_Driver(){
      if(this.value==="id") {
        this.Driver.id = this.searchText_driver
        this.Driver.name=null
        this.Driver.username=null
      }else if(this.value==="name"){
        this.Driver.name=this.searchText_driver
        this.Driver.id=null
        this.Driver.username=null
      }
      else if(this.value==="username"){
        this.Driver.username = this.searchText_driver
        this.Driver.name=null
        this.Driver.id=null
      }
      this.fetchDriver()
    },
    deleteByRow(id){
      // 确认删除的提示
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 发送删除请求到后端
        axios.delete(`/nurse/delete/${id}`).then((response) => {
          // 根据响应判断删除成功
          if (response.data.code === "200" || response.data.success) {
            this.fetchNurse()
          } else {
            // 删除失败
            this.$message({
              type: 'error',
              message: '删除失败'
            });
          }
        }).catch(error => {
          console.error('删除请求失败:', error);
          this.$message({
            type: 'error',
            message: '删除请求失败'
          });
        });
      }).catch(() => {
        // 用户取消删除
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleteByRow_Driver(id){
      // 确认删除的提示
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 发送删除请求到后端
        axios.delete(`/driver/delete/${id}`).then((response) => {
          // 根据响应判断删除成功
          if (response.data.code === "200" || response.data.success) {
            // 从前端删除数据
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchDriver()
          } else {
            // 删除失败
            this.$message({
              type: 'error',
              message: '删除失败'
            });
          }
        }).catch(error => {
          console.error('删除请求失败:', error);
          this.$message({
            type: 'error',
            message: '删除请求失败'
          });
        });
      }).catch(() => {
        // 用户取消删除
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleteByRow_Area(id){
      // 确认删除的提示
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 发送删除请求到后端
        axios.delete(`/area/${id}`).then((response) => {
          // 根据响应判断删除成功
          if (response.data.code === 200 || response.data.success) {
            // 从前端删除数据
            this.tableData_Area = this.tableData_Area.filter(item => item.id !== id);
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            // 删除失败
            this.$message({
              type: 'error',
              message: '删除失败'
            });
          }
        }).catch(error => {
          console.error('删除请求失败:', error);
          this.$message({
            type: 'error',
            message: '删除请求失败'
          });
        });
      }).catch(() => {
        // 用户取消删除
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    fetchNurse(){
      this.$http({
        url: "/nurse/selectAll",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data:JSON.stringify({
          id:this.Nurse.id,
          username:this.Nurse.username,
          name:this.Nurse.name,
        })
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableData = res.data.data;
        console.log(res);
      })
    },
    //展示洗浴记录
    fetchRecords(){
      axios({
        url: "/afterRecords/selectAllAdmin",               // 请求地址
        method: "get",                       // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableDateRecord = res.data.data;
        console.log(res);
      });
    },
    fetchDriver(){
      axios({
        url: "/vehicle/selectByDNforCI",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id:this.Driver.id,
          username:this.Driver.username,
          password:null,
          name:this.Driver.name,
          phone:null,
          age:null,
          identity:null,
          avatar: null,
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableData_driver = res.data.data;
        console.log(res);

      });
    },
    fetchArea(){
      axios({
        url: "/area/info",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: {
        },
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        if (res.data.code === "200") {  // 当响应的编码为 200 时，说明登录成功
          this.tableData_Area = JSON.stringify(res.data.data);
        }
      });
    },fetchPre(){
      axios({
        url: "/serveSign/selectAll",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id:null,
          phone:null,
          car_id:null,
          provinces:null,
          city:null,
          district:null,
          street:null,
          notes:null,
          fbegin:null,
          fend:null,
          begin:null,
          end:null,
          status:null,
          state:null,
        })
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableData_pre = res.data.data;
        console.log(res);
      });
    },
    handleClick(row) {
      // 显示模态框并填充当前行数据
      this.editForm = { ...row };
      this.editDialogVisible = true;
    },
    handleClick_Driver(row){
      // 显示模态框并填充当前行数据
      this.editForm_Driver = { ...row };
      this.editDialogVisible_Driver= true;
    },
    handleSubmitEdit() {
      // 表单提交逻辑
      // 这里可以包括表单验证、发送请求到后端等操作
      // 验证逻辑示例
      /**/
      // 发送更新请求
      axios.put(`/nurse/update`, this.editForm)
          .then(response => {
            // 表单提交成功后的逻辑
            this.$message.success('更新成功！');
            // 更新表格数据
            this.fetchNurse()
            // 关闭模态框
            this.editDialogVisible = false;
          })
          .catch(error => {
            // 错误处理逻辑
            this.$message.error('更新失败，请稍后重试！');
            console.error('更新请求失败:', error);
          });
    },
    handleSubmitEdit_Driver() {
      // 表单提交逻辑
      // 这里可以包括表单验证、发送请求到后端等操作
      // 验证逻辑示例
      /**/
      // 发送更新请求
      axios.put('/driver/update', this.editForm_Driver)
          .then(response => {
            // 表单提交成功后的逻辑
            this.$message.success('更新成功！');
            // 更新表格数据
            this.fetchDriver()
            // 关闭模态框
            this.editDialogVisible_Driver = false;
          })
          .catch(error => {
            // 错误处理逻辑
            this.$message.error('更新失败，请稍后重试！');
            console.error('更新请求失败:', error);
          });
    }
    //个人中心页面的初始化
    ,//个人中心页面的初始化
    initializeForm() {
      this.$http({     // axios 向后端发起请求
        url: `admin/selectById/${sessionStorage.getItem('user_id')}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.ruleForm=res.data.data;
        console.log(res)
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    //展示浴后评估表
    fetchAfter(){
      // 使用 axios 将登录信息发送到后端
      axios({
        url: "/afterRecords/selectAll",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          name:null,
          gender:null,
          time:null,
          blood_glucose: null,
          blood_pressure: null,
          heart_rate: null,
          spo2:null,
          temp:null,
          before_time: null,
          after_time: null,
          malasize: null,
        })
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableDateAfter = res.data.data;
        console.log(res)
      });
    },
    fetchBefore(){
      axios({
        url: "/beforeRecords/selectAll",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          name:null,
          gender:null,
          address:null,
          FamilyPhone: null,
          FanilyName: null,
          anamnesis: null,
          result: null,
        })
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableDateBefore = res.data.data;
        console.log(res);
      });
    },
    selectPage (page) {
      this.currentPage = page
    },
    logout () {
      console.log('---------------------')
      // 清除身份验证状态
      sessionStorage.removeItem('userInfo');
      sessionStorage.removeItem('token');

      // 重定向到登录页面
      this.$router.push('/')
    },
    updateData() {
      this.$http({     // axios 向后端发起请求
        url: "/admin/update",  // 请求地址
        method: "put",             // 请求方法
        headers: {                  // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id:this.ruleForm.id,
          username: this.ruleForm.username,
          password:this.ruleForm.password,
          name:this.ruleForm.name,
          email:this.ruleForm.email,
          phone:this.ruleForm.phone
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        if (res.data.code === "200") {  // 当响应的编码为 200 时，说明注册成功
          // 显示后端响应的成功信息
          this.initializeForm()
          this.$message({
            message: "更新成功",
            type: "success",
          });
        }else{
          this.$message({
            message: "更新失败",
            type: "error",
          });
        }
      })
    },
    //审核按钮
    handleClick_pre(row) {
      // 显示模态框并设置当前行数据
      this.dialogVisible = true;
      this.currentRowData = { ...row };
    },
    handleClick_pre_2(row){
      this.editDialogVisible_Edit = true;
      this.editForm_edi = { ...row };
    },
    handleApprove() {
      this.$http({     // axios 向后端发起请求
        url: "/serveSign/update",  // 请求地址
        method: "put",             // 请求方法
        headers: {                  // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id:this.currentRowData.id,
          car_id: this.currentRowData.car_id,
          status:'已通过',
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        if (res.data.code === "200") {  // 当响应的编码为 200 时，说明注册成功
          this.fetchPre();
          // 显示后端响应的成功信息
          this.initializeForm()
          this.$message({
            message: "审核成功",
            type: "success",
          });
        }else{
          this.$message({
            message: "审核失败",
            type: "error",
          });
        }
      })
      this.dialogVisible = false;
    },
    handleReject(row) {
      this.$http({     // axios 向后端发起请求
        url: "/serveSign/update",  // 请求地址
        method: "put",             // 请求方法
        headers: {                  // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id:this.currentRowData.id,
          car_id: this.currentRowData.car_id,
          state:'未通过',
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        if (res.data.code === "200") {  // 当响应的编码为 200 时，说明注册成功
          // 显示后端响应的成功信息
          this.fetchPre()
          this.$message({
            message:res.data.msg,
            type: "success",
          });
        }else{
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      })
      this.dialogVisible = false;
    },
    //审核编辑更新
    updateDatabase(){
      this.$http({     // axios 向后端发起请求
        url: "/serveSign/update",  // 请求地址
        method: "put",             // 请求方法
        headers: {                  // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id:this.editForm_edi.id,
          province:this.editForm_edi.province,
          city:this.editForm_edi.city,
          district: this.editForm_edi.district,
          street:this.editForm_edi.street,
          car_id: this.editForm_edi.car_id
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        if (res.data.code === "200") {  // 当响应的编码为 200 时，说明注册成功
          this.fetchPre()
          // 显示后端响应的成功信息
          this.initializeForm()
          this.$message({
            message: "修改成功",
            type: "success",
          });
        }else{
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      })
      this.editDialogVisible_Edit=false
    },
    handleSelect(key){
      this.currentPage = key
    },
    handleCommand(command){
      if(command === 'e'){
        this.logout()
      }else{
        this.currentPage = '个人中心'
      }
    }
  }
}
</script>
