<template>
<div class="top_div">
  <div style="width: 100%;height: 4%;">
    <h1 class="welcome-text" style="margin-right:1500px">助洛管理系统</h1>
  </div>
  <el-divider></el-divider>
  <div  style="width: 40%;height: 100%;float: right">
    <div style="margin: 200px auto;width: 60%;height: 45%;border: 1px solid wheat">
      <h2 class="welcome-text">欢 迎 使 用</h2>
      <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-position="left"
          label-width="70px"
          class="login-from"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="ruleForm.username"></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="userPwd">
          <el-input
              type="password"
              v-model="ruleForm.userPwd"
              autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="权限" prop="roleName" >
          <el-select v-model="ruleForm.roleName" placeholder="请选择" class="el-form-item-select">
            <el-option label="管理员" value="ADMIN"></el-option>
            <el-option label="老年人用户" value="OLDMAN"></el-option>
            <el-option label="护士" value="NURSE"></el-option>
            <el-option label="司机" value="DRIVER"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-button
          type="primary"
          size="medium"
          @click="submitForm('ruleForm')"
          v-loading="loading"
      >登录</el-button
      >
      <div class="register-link">
        还没有账号？请 <router-link to="/register">注册</router-link>
      </div>
    </div>
  </div>
  <!--图片展示区--->
  <div class="pic_iv"></div>

</div>
</template>

<style scoped>
.top_div{
  width: 100%;
  height: 100%;
  position: fixed;
}
.pic_iv{
 width: 60%;
  height: 100%;
  float: right;
  background-image: url("../assets/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.welcome-text {
  text-align: center;
  font-size: 24px;
  color: #2a60c9;
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  max-width: 350px; /* 设置一个最大宽度限制 */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
}
.register-link {
  margin-top: 25px;
  text-align: center;
}

.register-link a {
  color: #2a60c9;
  text-decoration: none;
}

.register-link a:hover {
  text-decoration: underline;
}

.el-form-item-select{
  width: 100%;
  border-radius: 4px;
  margin-top: 5px;
}
</style>

<script>
export default {
  data() {
    return {
      ruleForm: {
        username: "",
        userPwd: "",
        roleName:"",
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空！", trigger: "blur" },
        ],
        userPwd: [
          { required: true, message: "密码不能为空！", trigger: "blur" },
        ],
        roleName:[
          {required:true,message:"用户权限不能为空!",trigger: "blur"}
        ]
      },
      loading: false, // 是否显示加载动画
    };
  },
  methods: {
    submitForm(formName) {
      // 验证表单中的账号密码是否有效，因为在上面rules中定义为了必填 required: true
      this.$refs[formName].validate((valid) => {
        // 点击登录后，让登录按钮开始转圈圈（展示加载动画）
        this.loading = true;
        // 如果经过校验，账号密码都不为空，则发送请求到后端登录接口
        if (valid) {
          let _this = this;
          // 使用 axios 将登录信息发送到后端
          this.$http({
            url: "/login",               // 请求地址
            method: "post",                       // 请求方法
            headers: {                            // 请求头
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              username: _this.ruleForm.username,
              password: _this.ruleForm.userPwd,
              role: _this.ruleForm.roleName
            }),
          }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
            if (res.data.code === "200") {  // 当响应的编码为 0 时，说明登录成功
              // 将用户信息存储到sessionStorage中
              sessionStorage.setItem("userinfo",res.data.data.role);
              sessionStorage.setItem("name",res.data.data.name);
              sessionStorage.setItem("username",res.data.data.username);
              sessionStorage.setItem("user_id",res.data.data.id);
              sessionStorage.setItem("token", res.data.data.token);
              console.log(sessionStorage.getItem("userinfo"));
              console.log('=========='+sessionStorage.getItem("name"));
              // 显示后端响应的成功信息
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              // 跳转页面到
              this.navigateBasedOnRole(this.ruleForm.roleName);

            } else {  // 当响应的编码不为 200 时，说明登录失败
              // 显示后端响应的失败信息
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
            // 不管响应成功还是失败，收到后端响应的消息后就不再让登录按钮显示加载动画了
            _this.loading = false;
            console.log(res);
          });
        } else {  // 如果账号或密码有一个没填，就直接提示必填，不向后端请求
          console.log("error submit!!");
          this.loading = false;
          return false;
        }
      });
    },
    // 新增方法，根据选中的角色跳转
    navigateBasedOnRole(role) {
      // 根据角色重定向到不同页面
      const routes = {
        ADMIN: '/Admin',
        OLDMAN: '/Oldman',
        NURSE: '/Nurse',
        DRIVER:'/Driver'
        // 其他角色的路由
      };
      this.$router.push(routes[role]);
    },
  }
}
</script>
