<template>
  <div class="top_div">
    <div style="width: 100%;height: 4%;">
      <h1 class="welcome-text" style="margin-right:1500px">助洛管理系统</h1>
    </div>
    <el-divider></el-divider>
    <!--展示图片-->
    <div class="pic_div"></div>
    <!--注册组件-->
    <div class="register-container">
      <div class="register-form">
        <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-position="left"
            label-width="70px"
            class="login-from"
        >
          <el-form-item label="用户名" prop="username" label-width="200px">
            <el-input v-model="ruleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" label-width="200px">
            <el-input
                type="password"
                v-model="ruleForm.password"
                autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="ConfirmUserPwd" label-width="200px">
            <el-input
                type="password"
                v-model="ruleForm.ConfirmUserPwd"
                autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard" label-width="200px">
            <el-input v-model="ruleForm.idCard"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-radio v-model="ruleForm.sex" label="男">男</el-radio>
            <el-radio v-model="ruleForm.sex" label="女">女</el-radio>
          </el-form-item>
          <el-form-item label="真实姓名" prop="name" label-width="200px">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="老人电话" prop="phone" label-width="200px">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="家庭住址" prop="address" label-width="200px">
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="ruleForm.address">
            </el-input>
          </el-form-item>
          <el-form-item label="基础疾病" prop="illness" label-width="200px">
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="ruleForm.illness">
            </el-input>
          </el-form-item>
          <el-form-item label="紧急联系人姓名" prop="fname" label-width="200px">
            <el-input v-model="ruleForm.fname"></el-input>
          </el-form-item>
          <el-form-item label="紧急联系人手机号" prop="fnumber" label-width="200px">
            <el-input v-model="ruleForm.fnumber"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="submitForm('ruleForm')"  v-loading="loading"
        >提交</el-button
        >
        <div class="login-link">
          已有账号？请 <router-link to="/">登录</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        sex:'',
        username: "",
        password: "",
        ConfirmUserPwd: "",
        address: '',
        idCard:'',
        phone:'',
        illness: '',
        fname:'',
        fnumber:'',
        name:'',
        role:'OLDMAN'

      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空！", trigger: "blur" },
        ],
        password: [{ required: true, validator: validatePass, trigger: "blur" }],
        ConfirmUserPwd: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        /*address: [{ required: true,message: "地址不能为空！", trigger: "blur" }],
        illness: [{ required: true,message: "基础疾病不能为空！" ,trigger: "blur" }],*/
        idCard: [{ required: true,message: "身份证号不能为空！" ,trigger: "blur" }],
        /*phone: [{ required: true, message: "电话号码不能为空！",trigger: "blur" }],
        fnumber: [{ required: true, message: "紧急联系电话不能为空！",trigger: "blur" }],
        fname: [{ required: true, message: "紧急联系人不能为空！",trigger: "blur" }],
        sex: [{ required: true,message: "性别不能为空！", trigger: "blur" }],*/
        name: [{ required: true,message: "真实姓名不能为空！", trigger: "blur" }],
      },
      loading: false
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        this.loading = true;  // 提交按钮显示加载动画
        if (valid) {
          let _this = this;
          this.$http({     // axios 向后端发起请求
            url: "/register",  // 请求地址
            method: "post",             // 请求方法
            headers: {                  // 请求头
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              username: _this.ruleForm.username,
              password: _this.ruleForm.password,
              idcard:_this.ruleForm.idCard,
              gender: _this.ruleForm.sex,
              phone:_this.ruleForm.phone,
              address:_this.ruleForm.address,
              illness:_this.ruleForm.illness,
              fname:_this.ruleForm.fname,
              fnumber:_this.ruleForm.fnumber,
              name:_this.ruleForm.name,
              role:_this.ruleForm.role
            }),
          }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
            if (res.data.code === "200") {  // 当响应的编码为 200 时，说明注册成功
              // 显示后端响应的成功信息
              this.$message({
                message: "注册成功",
                type: "success",
              });
            }else{  // 当响应的编码不为 200 时，说明注册失败
              // 显示后端响应的失败信息
              this.$message({
                message: "注册失败",
                type: "warning",
              });
            }
            // 不管响应成功还是失败，收到后端响应的消息后就不再让登录按钮显示加载动画了
            _this.loading = false;
            console.log(res);
          });
        } else { // 如果账号或密码有一个没填，就直接提示必填，不向后端请求
          console.log("error submit!!");
          this.loading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.top_div{
  width: 100%;
  height: 100%;
  position: absolute;
}
.pic_div{
  width: 60%;
  height: 100%;
  float: left;
  background-image: url("../assets/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.register-form {
  width: 500px;
  height: 95%;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.welcome-text {
  text-align: center;
  font-size: 24px;
  color: #2a60c9;
  margin-bottom: 20px;
}

.login-link {
  margin-top: 10px;
  text-align: center;
}

.login-link a {
  color: #2a60c9;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}
.register-container {
  width: 40%;
  height: 100%;
  background-color: #f8f8f8;
  float: right;
}

</style>
