<template>
  <div class="top_div">
    <!--侧边导航栏---->
    <div style="width: 11.8%;height: 100%;float: left">
      <el-menu default-active="1-4-1" class="el-menu-vertical-demo"  @select="handleSelect" >
        <el-menu-item index="活动" style="height: 30%;text-align: left" >
          <i class="el-icon-s-comment"></i>
          <span slot="title">活动</span>
        </el-menu-item>
        <el-menu-item index="洗浴" style="height: 30%;text-align: left">
          <i class="el-icon-s-comment"></i>
          <span slot="title">洗浴</span>
        </el-menu-item>
        <el-menu-item index="车辆状况" style="height: 30%;text-align: left">
          <i class="el-icon-document"></i>
          <span slot="title">车辆状况</span>
        </el-menu-item>
        <el-menu-item index="个人中心" style="height: 30%;text-align: left">
          <i class="el-icon-document"></i>
          <span slot="title">个人中心</span>
        </el-menu-item>
      </el-menu>
    </div>

    <!--具体内容----->
    <div  style="width: 88.2%;height: 100%;float: left;position: relative">
      <div style="width: 100%;height: 10%;background-color: cadetblue">
        <div style="width: 100%;height: 50%;background-color: cadetblue">
          <i class="el-icon-s-fold"
             style="font-size: 25px;padding-right: 1400px;padding-top: 10px"
          ></i>
          <el-dropdown style="float: right;" @command="handleCommand">
            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="d">个人中心</el-dropdown-item>
              <el-dropdown-item command="e" divided>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>


      </div>

      <div v-if="currentPage === '活动'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <div style="width: 100%;height: 100%;float: left">

          <div style="padding-top: 30px">
            <el-table
                :data="tableData_Ac"
                style="width: 100%;border: 1px black solid"
            >
              <el-table-column
                  label="活动号"
                  prop="id"
                  width="80">
              </el-table-column>
              <el-table-column
                  label="省份"
                  prop="province"
                  width="80">
              </el-table-column>
              <el-table-column
                  label="市"
                  prop="city"
                  width="80">
              </el-table-column>
              <el-table-column
                  label="区"
                  prop="district"
                  width="80" >
              </el-table-column>
              <el-table-column
                  label="街道"
                  prop="street"
                  width="80">
              </el-table-column>
              <el-table-column
                  label="备注"
                  prop="notes"
                  width="150">
              </el-table-column>
              <el-table-column
                  label="活动状态"
                  prop="state"
                  width="100">
              </el-table-column>
              <el-table-column
                  label="预期开始时间"
                  prop="fbegin"
                  width="150">
              </el-table-column>
              <el-table-column
                  label="预期结束时间"
                  prop="fend"
                  width="150">
              </el-table-column>
              <el-table-column
                  label="开始时间"
                  prop="begin"
                  width="160">
              </el-table-column>
              <el-table-column
                  label="结束时间"
                  prop="end"
                  width="160">
              </el-table-column>
              <el-table-column label="操作" align="center" width="230" class-name="small-padding fixed-width">
                <template slot-scope="{row,$index}">
                  <el-button type="primary" size="mini" @click="startAc(row)">
                    开始
                  </el-button>
                  <el-button  size="mini" type="primary" @click="EventEnd(row)">
                    结束
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>


        </div>


      </div>

      <div v-if="currentPage === '洗浴'"
           style="width: 100%;height:100%;
           margin: 10px auto">

        <div style="width: 75%;height: 100%;float: left">
          <div style="float: left">
            <el-input v-model="text_identity" placeholder="请输入身份证信息" style="width: 200px;" />
            <el-button  class="filter-item" type="primary" icon="el-icon-search" @click="searchIdentity()">
              搜索
            </el-button>
            <!--          <el-button class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">
                        添加
                      </el-button>-->
          </div>

          <div style="padding-top: 60px">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>本次洗浴详情</span>
                <div><el-button @click="serveEnd()" type="text" size="small" style="color: red;float: right">结束洗浴</el-button></div>
              </div>
              <div  class="text item">
                <el-form ref="ServeSignNEW" :model="ServeSignNEW" >
                  <el-form-item label="老人id" label-width="60px">
                    <el-input v-model="ServeSignNEW.oldman_id" style="width: 200px" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="洗浴id" label-width="60px">
                    <el-input v-model="ServeSignNEW.serve_id" style="width: 200px" :disabled="true"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
          </div>


        </div>


      </div>

      <div v-if="currentPage === '车辆状况'"
           style="width: 100%;height:100%;
           margin: 10px auto">

        <div style="width: 85%;height: 100%;float: left">

          <div style="padding-top: 30px">
            <el-table
                :data="tableData_Car"
                border
                style="width: 62%;border: 1px black solid"
            >
              <el-table-column
                  label="车牌号"
                  prop="car_id"
                  width="120">
              </el-table-column>
              <el-table-column
                  label="司机姓名"
                  prop="name"
                  width="120">
              </el-table-column>
              <el-table-column
                  label="用户名"
                  prop="username"
                  width="120">
              </el-table-column>
              <el-table-column
                  label="当前车辆状况"
                  prop="state"
                  width="120">
              </el-table-column>
              <el-table-column label="操作" align="center" width="300" class-name="small-padding fixed-width">
                <template slot-scope="{row,$index}">
                  <el-radio v-model="row.state" label="可使用">可使用</el-radio>
                  <el-radio v-model="row.state" label="不可使用">不可使用</el-radio>
                  <el-button type="primary" size="mini" @click="updateState(row)">
                    更改
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

          </div>


        </div>


      </div>

      <div v-if="currentPage === '个人中心'"
           style="width: 100%;height: 100%;
           margin: 10px auto">
        <div class="login-form" style="width: 35%; height: 550px;float: left">
          <el-form
              :model="ruleForm"
              status-icon
              ref="ruleForm"
              label-position="left"
              label-width="70px"
              class="login-from"
          >

            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" size="large"></el-avatar>
            <el-divider></el-divider>
            <el-form-item label="ID" prop="id" label-width="80px">
              <el-input v-model="ruleForm.id" style="width: 300px" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="用户名" prop="username" label-width="80px">
              <el-input v-model="ruleForm.username" style="width: 300px"></el-input>
            </el-form-item>

            <el-form-item label="密码" prop="password" label-width="80px">
              <el-input
                  type="password"
                  v-model="ruleForm.password"
                  autocomplete="off"
                  style="width: 300px"
              ></el-input>
            </el-form-item>

            <el-form-item label="姓名" prop="name" label-width="80px">
              <el-input v-model="ruleForm.name" style="width: 300px"></el-input>
            </el-form-item>

            <el-form-item label="年龄" prop="age" label-width="80px">
              <el-input v-model="ruleForm.age" style="width: 300px"></el-input>
            </el-form-item>

            <el-form-item label="所属车辆" label-width="80px">
              <el-input v-model="ruleForm.car_id" style="width: 300px" :disabled="true"></el-input>
            </el-form-item>

          </el-form>


          <el-button type="primary" round @click="updateData">更新数据</el-button>
        </div>

      </div>





    </div>

  </div>
</template>

<style scoped>
.top_div{
  width: 100%;
  height:100%;
  position: relative;
}
.login-form {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>

<script>
import axios from "axios";

export default {
  data () {
    return {
      ServeSignNEW:{
        serve_id:'',
        oldman_id:'',
      },
      //输入身份证号进行搜索
      text_id:'',
      text_identity:'',
      ServeSign:{
        id:null,
        serve_id:null,
        oldman_id:null,
        datetime:null,
        province:null,
        city:null,
        district:null,
        state:null,
        status:null,
        fbegin:null,
        fend:null,
        begin:null,
        car_id:null,
        notes:null
      },
      //个人中心
      ruleForm: {
        id:'',
        username: "",
        password: "",
        name:"",
        identity:"",
        car_id:'',
      },
      //车辆状况
      tableData_Car:[],
      //活动
      tableData_Ac:[],
      currentPage: '活动',
      areaInfo: {},
      vehicleInfo: {},
      vehicleStatus: '',
      currentStatus: '',
      avatarFile: null, // 用户选择的头像文件
      isEditingProfile: false,
    }
  },created(){
    this.initializeForm();//个人中心的初始化
    this.fetchCarState(); //显示车辆状况
  },
  methods: {
    //开始活动
    startAc(row){
      this.$http({
        url: "/serveSign/updateDr",               // 请求地址
        method: "put",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id:row.id ,
          state:'已开始',
          car_id:row.car_id
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        if (res.data.code === "200") {  // 当响应的编码为 0 时，说明登录成功
          //将表清空
          this.resetIdentity()
          this.text_identity = null,
              this.fetchAc()
          // 显示后端响应的成功信息
          this.$message({
            message: "活动已开始",
            type: "success",
          });
        }else{
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      })
    },
    //结束当前活动
    EventEnd(row){
      this.$http({
        url: "/serveSign/updateDr",               // 请求地址
        method: "put",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id:row.id ,
          state:'已结束',
          car_id: row.car_id
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        if (res.data.code === "200") {  // 当响应的编码为 0 时，说明登录成功
          this.fetchAc()
          // 显示后端响应的成功信息
          this.$message({
            message: "活动已结束",
            type: "success",
          });
        }else{
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      })
    },
    //结束当前洗浴
    serveEnd(){
      this.text_identity=''
      this.text_id=''
      this.ServeSign.oldman_id=''
      this.ServeSign.serve_id=''
      this.$message({
        message:'本次洗浴活动结束',
        type:'success'
      })
    },
    //根据身份证号进行查询对应的洗浴id
    searchIdentity(){
      if(this.text_identity==''){
        this.$message({
          message:"身份证号为空",
          type:"error",
        })}else {
        axios({
          url: `/afterRecords/returnId/${this.text_identity}`,               // 请求地址
          method: "get",                       // 请求方法
        }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
          console.log(res);
          if (res.data.code === '200') {
            const id = res.data.data
            this.ServeSignNEW.serve_id = id
            this.$message({
              message: '本次洗浴活动开始,洗浴号为:' + id,
              type: 'success'
            })
            this.fetchServeSign(this.text_identity)
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      }
    },
    //根据身份证号查老年人id
    fetchServeSign(id){
      axios({
        url: `Oldman/selectByIdcardforId/${id}`,               // 请求地址
        method: "get",                       // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.ServeSignNEW.oldman_id = res.data.data;
        console.log(res);
      });
    },
    //活动
    fetchAc(){
      axios({
        url: "/serveSign/selectAll",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data:JSON.stringify({
          car_id:this.ruleForm.car_id,
          province:null,
          city:null,
          district:null,
          street: null,
          notes:null,
          fbegin: null,
          fend:null,
          begin:null,
        })
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableData_Ac = res.data.data;
        console.log(res);
      });
    },
    //显示车辆状况
    fetchCarState(){
      axios({
        url: "/vehicle/selectByDNforCI",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id:sessionStorage.getItem('user_id')
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableData_Car = res.data.data;
        console.log(res);

      });
    },
    //更新车辆状态
    updateState(row){
      axios({
        url: "/vehicle/update",               // 请求地址
        method: "put",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          car_id:this.ruleForm.car_id,
          state:row.state
        })
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        if (res.data.code === "200") {  // 当响应的编码为 200 时，说明登录成功
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.fetchCarState()
        }else{
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //清空查询出来的表
    resetIdentity(){
      this.ServeSign.id=null,
          this.ServeSign.oldman_id=null,
          this.ServeSign.datetime=null,
          this.ServeSign.province=null,
          this.ServeSign.city=null,
          this.ServeSign.district=null,
          this.ServeSign.state=null,
          this.ServeSign.fbegin=null,
          this.ServeSign.fend=null,
          this.ServeSign.begin=null,
          this.ServeSign.end=null,
          this.ServeSign.car_id=null,
          this.ServeSign.notes=null
    },
    //更新个人资料
    updateDataState() {
      this.$http({
        url: "/driver/update",               // 请求地址
        method: "put",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          username:this.ruleForm.username,
          password:this.ruleForm.password,
          name:this.ruleForm.name,
          age:this.ruleForm.age,
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        if (res.data.code === "200") {  // 当响应的编码为 0 时，说明登录成功
          // 显示后端响应的成功信息
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        }else{
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      })
    },
    //个人中心页面的初始化
    initializeForm() {
      axios({
        url: "/vehicle/selectByDNforCI",               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id:sessionStorage.getItem('user_id')
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.ruleForm = res.data.data[0];
        console.log(res);
        this.fetchAc()
      });
    },
    //更新个人中心数据
    updateData(){
      // 发送更新请求到后端API
      // 发送更新请求到后端API
      axios({
        url: "/driver/update",               // 请求地址
        method: "put",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id: this.ruleForm.id,
          username: this.ruleForm.username,
          password: this.ruleForm.password,
          name: this.ruleForm.name,
          age:this.ruleForm.age,
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        console.log(res)
        if (res.data.code==="200") {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    selectPage(page) {
      this.currentPage = page;
    },
    logout () {
      // 清除身份验证状态
      sessionStorage.removeItem('userInfo');
      sessionStorage.removeItem('token');

      // 重定向到登录页面
      this.$router.push('/')
    },
    handleSelect(key){
      this.currentPage = key
    },
    handleCommand(command){
      if(command === 'e'){
        this.logout()
      }else{
        this.currentPage = '个人中心'
      }
    }

  }
}
</script>
