<template>
  <div class="top_div">
    <!--侧边导航栏---->
    <div style="width: 11.8%;height: 100%;float: left">
      <el-menu default-active="1-4-1" class="el-menu-vertical-demo"  @select="handleSelect" >
        <el-menu-item index="首页" style="height: 30%;text-align: left" >
          <i class="el-icon-s-comment"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <el-menu-item index="助浴车洗浴" style="height: 30%;text-align: left">
          <i class="el-icon-s-comment"></i>
          <span slot="title">助浴车洗浴</span>
        </el-menu-item>
        <el-menu-item index="我的预约" style="height: 30%;text-align: left">
          <i class="el-icon-document"></i>
          <span slot="title">我的预约</span>
        </el-menu-item>
        <el-menu-item index="我的洗浴记录" style="height: 30%;text-align: left">
          <i class="el-icon-edit"></i>
          <span slot="title">我的洗浴记录</span>
        </el-menu-item>
        <el-menu-item index="个人中心" style="height: 30%;text-align: left">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">个人中心</span>
        </el-menu-item>
      </el-menu>
    </div>

    <!--具体内容----->
    <div  style="width: 88.2%;height: 100%;float: left;position: relative">
      <div style="width: 100%;height: 10%;background-color: cadetblue">
        <div style="width: 100%;height: 50%;background-color: cadetblue">
          <i class="el-icon-s-fold"
             style="font-size: 25px;padding-right: 1400px;padding-top: 10px"
          ></i>
          <el-dropdown style="float: right;" @command="handleCommand">
            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="d">个人中心</el-dropdown-item>
              <el-dropdown-item command="e" divided>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>


      </div>

      <div v-if="currentPage === '首页'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <div style="width: 92%;height: 100%;float: left">
          <div style="float: left">
            <el-select @change="cityInfo" v-model="province" placeholder="请选择省份" style="width: 150px">
              <el-option
                  v-for="item in provinceAll"
                  :key="item.province"
                  :label="item.province"
                  :value="item.province">
              </el-option>
            </el-select>

            <!--下拉框-市级---->

            <el-select @change="areaInfo" v-model="city" placeholder="请选择城市" style="width: 150px">
              <el-option
                  v-for="item in cityAll"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>


            <!--下拉框-区级---->

            <el-select @change="streetInfo" v-model="area" placeholder="请选择区域" style="width: 150px">
              <el-option
                  v-for="item in AreaAll"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>


            <!---下拉框-区级---->
            <el-select  @change="AcArea()" v-model="street" placeholder="请选择街道" style="width: 140px">
              <el-option
                  v-for="item in streetAll"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>

          <div style="padding-top: 60px">
            <el-table
                :data="tableDataAc"
                border
                style="width: 85%;border: 1px black solid"
            >
              <el-table-column label="活动号" prop="id"  align="center" width="80" >
              </el-table-column>
              <el-table-column
                  prop="province"
                  label="省"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="city"
                  label="市"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="district"
                  label="区"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="street"
                  label="街"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="state"
                  label="活动状态"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="phone"
                  label="手机号"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="fbegin"
                  label="预计开始时间"
                  width="200">
              </el-table-column>
              <el-table-column
                  prop="fend"
                  label="预计结束时间"
                  width="200">
              </el-table-column>
            </el-table>
          </div>


        </div>


      </div>


      <div v-if="currentPage === '助浴车洗浴'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <el-form ref="form" :model="form" label-width="80px" style="margin: 10px auto;border: 1px solid black;border-radius: 2px">
          <br/>
          <el-form-item label="活动区域">
            <div style="text-align: left">
              <!--下拉框-省级---->

              <el-select @change="judge_city" v-model="form.province" placeholder="请选择省份" style="width: 150px">
                <el-option
                    v-for="item in options_nation"
                    :key="item.province"
                    :label="item.province"
                    :value="item.province">
                </el-option>
              </el-select>
              <!--下拉框-市级---->

              <el-select @change="judge_area" v-model="form.city" placeholder="请选择城市" style="width: 150px">
                <el-option
                    v-for="item in options_city"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>

              <!--下拉框-区级---->

              <el-select @change="judge_street" v-model="form.area" placeholder="请选择区域" style="width: 150px">
                <el-option
                    v-for="item in options_area"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>

              <!---下拉框-街道---->
              <el-select  v-model="form.street" placeholder="请选择街道" style="width: 140px">
                <el-option
                    v-for="item in options_street"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>

          </el-form-item><br/>

          <div style="text-align: left">
            <el-form-item label="活动时间" label-width="80px">
              <el-col :span="11">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" value-format="yyyy-MM-dd" style="width: 80%;"></el-date-picker>
              </el-col>

              <el-col :span="11">
                <el-time-picker placeholder="选择时间" v-model="form.date2"  value-format="HH:mm:ss" style="width: 80%;"></el-time-picker>
              </el-col>
            </el-form-item>
          </div><br/>

          <div style="text-align: left">
            <el-form-item label="备注">

              <el-input
                  type="textarea"
                  :rows="6"
                  placeholder="请输入备注"
                  style="width: 500px"
                  v-model="form.text">
              </el-input>

            </el-form-item>
          </div>

          <el-form-item style="text-align: left">
            <el-button type="primary" @click="onSubmit('form')">提交</el-button>
            <el-button>重置</el-button>
          </el-form-item>
        </el-form>


      </div>

      <div v-if="currentPage === '我的预约'"
           style="width: 100%;height:100%;
           margin: 10px auto">

        <div style="width: 75%;height: 100%;float: left">

          <div style="padding-top: 30px">
            <el-table
                :data="tableDataPredict"
                border
                style="width: 100%;border: 1px black solid"
            >
              <el-table-column
                  prop="id"
                  label="活动号"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="province"
                  label="省"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="city"
                  label="市"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="district"
                  label="区"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="street"
                  label="街"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="status"
                  label="审核状态"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="phone"
                  label="手机号"
                  width="150">
              </el-table-column>

            </el-table>
          </div>


        </div>


      </div>

      <div v-if="currentPage === '我的洗浴记录'"
           style="width: 100%;height: 100%;
           margin: 10px auto">

        <div style="width: 100%;height: 100%;float: left">

          <div style="padding-top: 30px">
            <el-table
                :data="tableDataPrivate"
                border
                style="width: 100%;border: 1px black solid"
            >
              <el-table-column
                  fixed
                  prop="id"
                  label="序号"
                  width="100">
              </el-table-column>
              <el-table-column
                  fixed
                  prop="serve_id"
                  label="服务号"
                  width="100">
              </el-table-column>
              <el-table-column
                  fixed
                  prop="oldman_id"
                  label="老人序号"
                  width="150">
              </el-table-column>
              <el-table-column
                  fixed
                  prop="time"
                  label="时间"
                  width="150">
              </el-table-column>
              <el-table-column
                  fixed
                  prop="before_time"
                  label="浴前时间"
                  width="150">
              </el-table-column>
              <el-table-column
                  fixed
                  prop="after_time"
                  label="浴后时间"
                  width="150">
              </el-table-column>
              <el-table-column
                  fixed
                  prop="blood_pressure"
                  label="血压"
                  width="150">
              </el-table-column>
              <el-table-column
                  fixed
                  prop="heart_rate"
                  label="心率"
                  width="150">
              </el-table-column>
              <el-table-column
                  fixed
                  prop="temp"
                  label="温度"
                  width="150">
              </el-table-column>
              <el-table-column label="操作" align="center" width="230" class-name="small-padding fixed-width">
                <template slot-scope="{row,$index}">
                  <el-button type="primary" size="mini" @click="viewView(row)">
                    查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-dialog
                :visible.sync="ViewVisable"
                width="30%"
                style="height: 65%;">
              <el-card class="box-card" >
                <div slot="header" class="clearfix">
                  <span>监测数据</span>
                </div>
                <div class="text item">
                  <el-form ref="DataAvg" :model="DataAvg" >
                    <el-form-item label="CO" label-width="60px">
                      <el-input v-model="DataAvg.co" style="width: 200px" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="CO2" label-width="60px">
                      <el-input v-model="DataAvg.co2" style="width: 200px" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="温度" label-width="60px">
                      <el-input v-model="DataAvg.tem" style="width: 200px" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="湿度" label-width="60px">
                      <el-input v-model="DataAvg.hum" style="width: 200px" :disabled="true"></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </el-card>
            </el-dialog>


          </div>


        </div>


      </div>

      <div v-if="currentPage === '个人中心'"
           style="width: 100%;height: 100%;
           margin: 10px auto">
          <div class="login-form" style="width: 35%; height: 550px;float: left">
            <el-form
                :model="ruleForm"
                status-icon
                ref="ruleForm"
                label-position="left"
                label-width="70px"
                class="login-from"
            >

              <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" size="large"></el-avatar>
              <el-divider></el-divider>
              <el-form-item label="序号" prop="id" label-width="80px">
                <el-input v-model="ruleForm.id" style="width: 300px" :disabled="true"></el-input>
              </el-form-item>

              <el-form-item label="用户名" prop="username" label-width="80px">
                <el-input v-model="ruleForm.username" style="width: 300px" :disabled="true"></el-input>
              </el-form-item>


              <el-form-item label="姓名" prop="name" label-width="80px">
                <el-input v-model="ruleForm.name" style="width: 300px" :disabled="true"></el-input>
              </el-form-item>

              <el-form-item label="性别" prop="gender" label-width="80px">
                <el-input v-model="ruleForm.gender" style="width: 300px" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="年龄" prop="age" label-width="80px">
                <el-input v-model="ruleForm.age" style="width: 300px"></el-input>
              </el-form-item>

              <el-form-item label="电话" prop="phone" label-width="80px">
                <el-input v-model="ruleForm.phone" style="width: 300px"></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="idcard" label-width="80px">
                <el-input v-model="ruleForm.idcard" style="width: 300px"></el-input>
              </el-form-item>

            <el-button type="primary" round @click="updateData">更新数据</el-button>
            </el-form>
          </div>

      </div>


    </div>

  </div>
</template>

<style scoped>
.top_div{
  width: 100%;
  height:100%;
  position: relative;
}
.login-form {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      currentPage: '首页',
      //监测数据
      DataAvg:{
        co:'',
        co2:'',
        hum:'',
        tem:'',
      },
      //折线图的模态框
      ViewVisable : false,
      ViewData:[],
      value:'',
      chartInstance:null,
      //下拉框联动
      Province:{
        province:'',
        province_id:''
      },
      tableDataPredict: [], //自己的预约记录
      tableDataPrivate: [], //自己的洗浴记录
      form: {
        province: '',
        city: '',
        area: '',
        street: '',
        date1: '',
        date2: '',
        text: '',
      },
      province:'',
      provinceAll:[],
      city:'',
      cityAll:[],
      area:'',
      AreaAll:[],
      street:'',
      streetAll:[],
      tableDataAc:[],
      Area:{
        province:null,
        city:null,
        area:null,
        street:null,
      },
      //个人中心
      ruleForm: {
        avator: "",
        id: "",
        username: "",
        password: "",
        name: "",
        gender: "",
        age: "",
        phone: "",
        idcard:''
      },
      options_nation: [],
      options_city: [],
      options_area: [],
      options_street: [],

      service: {
        date: '',
        timeSlot: '',
        district: '',
        remarks: ''
      },
      dateError: '',
      selectedAreas: [],
      reservations: [], // 用于存储预约信息
      showDropdown: false,
      avatarFile: null, // 用户选择的头像文件
      isEditingProfile: false,
    }
  }, created() {
    this.fetchOptions_Province();//展示国家下拉框
    this.fetchPredict();    //展示自己的预约信息
    this.fetchPrivate();    //展示自己的洗浴记录
    this.fetchPrivateInfo(); //展示自己的数据
    this.fetchOptions_province();//首页
    this.fetchAc();
  },
  methods: {
    //折线图
    viewView(row){
      // 显示模态框并填充当前行数据
      this.ViewVisable= true;

      Promise.all([
        this.getTem(row.id),
        this.getHum(row.id),
        this.getCo(row.id),
        this.getCo2(row.id)
      ]).then(([data1, data2, data3, data4]) => {
        this.DataAvg.tem=data1
        this.DataAvg.hum=data2
        this.DataAvg.co=data3
        this.DataAvg.co2=data4
      }).catch(error => {
        console.error('请求失败:', error);
        // 处理请求错误
      });
    },
    getTem(id){
      return this.$http.get(`/recordtem/AVGtem/${id}`).then(response => {
        return response.data.data;
      });
    },
    getHum(id){
      return this.$http.get(`/recordhum/AVGhum/${id}`).then(response => {
        return response.data.data;
      });
    },
    getCo(id){
      return this.$http.get(`/recordco/AVGco/${id}`).then(response => {
        return response.data.data;
      });
    },
    getCo2(id){
      return this.$http.get(`/recordco2/AVGco2/${id}`).then(response => {
        return response.data.data;
      });
    },
    selectService(serviceType) {
      this.selectedService = serviceType;
    },
    // 处理退出登录操作
    logout() {
      // 清除身份验证状态
      sessionStorage.removeItem('userInfo');
      sessionStorage.removeItem('token');

      // 重定向到登录页面
      this.$router.push('/')
    },
    //查询所有的省份
    fetchOptions_Province() {
      this.$http({     // axios 向后端发起请求
        url: "/province/selectAllP",  // 请求地址
        method: "post",             // 请求方法
        headers: {                  // 请求头
          "Content-Type": "application/json",
          'TOKEN ': ` ${sessionStorage.getItem('token')}`
        },
        data: JSON.stringify({
          province_id:1,
          province:'江苏',
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.options_nation = res.data.data;
        console.log(res);
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },//查询所有的省份----首页
    fetchOptions_province() {
      this.$http({     // axios 向后端发起请求
        url: "/province/selectAllP",  // 请求地址
        method: "post",             // 请求方法
        headers: {                  // 请求头
          "Content-Type": "application/json",
          'TOKEN ': ` ${sessionStorage.getItem('token')}`
        },
        data: JSON.stringify({
          province_id:1,
          province:'江苏',
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.provinceAll = res.data.data;
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    judge_city() {
      console.log(this.form.province);
      this.$http({     // axios 向后端发起请求
        url: `city/selectPforC/${this.form.province}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.options_city = res.data.data;
        this.form.city=null;
        this.form.area=null;
        this.form.street=null;
        console.log(this.options_city);
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    //首页
    cityInfo() {
      this.$http({     // axios 向后端发起请求
        url: `city/selectPforC/${this.province}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.Area.city=null;
        this.Area.area=null;
        this.Area.street=null;
        this.city=null;
        this.area=null;
        this.street=null;
        this.cityAll = res.data.data;
        this.Area.province=this.province;
        this.fetchAc();
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    judge_area() {
      this.$http({     // axios 向后端发起请求
        url: `/district/selectCforD/${this.form.city}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.options_area = res.data.data;
        this.form.area=null;
        this.form.street=null;
        console.log(res.data.data);
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    areaInfo() {
      this.$http({     // axios 向后端发起请求
        url: `/district/selectCforD/${this.city}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.Area.area=null;
        this.Area.street=null;
        this.area=null;
        this.street=null;
        this.AreaAll = res.data.data;
        this.Area.city=this.city;
        this.fetchAc();
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    judge_street() {
      this.$http({     // axios 向后端发起请求
        url: `/street/selectDforS/${this.form.area}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.options_street = res.data.data;
        this.form.street=null;
        console.log(res.data.data);
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    streetInfo() {
      this.$http({     // axios 向后端发起请求
        url: `/street/selectDforS/${this.area}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.streetAll = res.data.data;
        this.Area.street=null;
        console.log(res.data.data);
        this.Area.area=this.area;
        this.fetchAc();
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    AcArea(){
      this.Area.street = this.street;
      this.fetchAc();
    },
    //预约表单的提交
    onSubmit(formName) {
      // 验证表单中的账号密码是否有效，因为在上面rules中定义为了必填 required: true
      this.$refs[formName].validate((valid) => {
        // 点击登录后，让登录按钮开始转圈圈（展示加载动画）
        this.loading = true;
        // 如果经过校验，账号密码都不为空，则发送请求到后端登录接口
        if (valid) {
          let _this = this;
          // 使用 axios 将登录信息发送到后端
          this.$http({
            url: "/serveSign/add",               // 请求地址
            method: "post",                       // 请求方法
            headers: {                            // 请求头
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              datetime: this.form.date1+" "+this.form.date2,
              province: this.form.province,
              city:this.form.city,
              district:this.form.area,
              street:this.form.street,
              oldman_id:sessionStorage.getItem("user_id"),
            }),
          }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
            console.log(this.form)
            console.log(res);
            // 检查后端响应中的状态或成功字段来确定是否展示消息
            if (res.data.code==="200") {
              alert('操作成功: ' + res.data.msg);
            } else {
              alert('操作失败: ' + res.data.msg);
            }
          });
        }
      });
    },
    //展示预约信息
    fetchPredict() {
      this.$http({     // axios 向后端发起请求
        url: `serveSign/selectByOid?oldman_id=${sessionStorage.getItem("user_id")}`,  // 请求地址
        method: "get",             // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableDataPredict = res.data.data;
        console.log(res);
      }).catch(error => {
        console.error("请求错误:", error);
      });
    },
    //展示洗浴记录
    fetchPrivate() {
      axios({
        url: `/afterRecords/selectAllOldman/${sessionStorage.getItem("user_id")}`,               // 请求地址
        method: "get",                       // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableDataPrivate = res.data.data;
        console.log(res)
      });
    },//展示个人资料
    fetchPrivateInfo() {
      axios({
        url: `/Oldman/selectById/${sessionStorage.getItem("user_id")}`,               // 请求地址
        method: "get",                       // 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.ruleForm = res.data.data;
        console.log(res)
      });
    },
    //展示所有的活动
    fetchAc(){
      axios({
        url: '/serveSign/selectAllOldman',               // 请求地址
        method: "post",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          province:this.Area.province,
          city:this.Area.city,
          district:this.Area.area,
          street:this.Area.street,
        }),// 请求方法
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        this.tableDataAc = res.data.data;
        console.log(res)
      }).catch(error => {
        console.error("请求失败:", error);
        // 可以在这里显示错误信息
      });
    },
    updateData() {
      // 发送更新请求到后端API
      axios({
        url: "/Oldman/update",               // 请求地址
        method: "put",                       // 请求方法
        headers: {                            // 请求头
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id: this.ruleForm.id,
          username: this.ruleForm.username,
          password: this.ruleForm.password,
          name: this.ruleForm.name,
          gender:this.ruleForm.gender,
          phone: this.ruleForm.phone,
          age:this.ruleForm.age,
          idcard: this.ruleForm.idcard
        }),
      }).then((res) => { // 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
        console.log(res)
        if (res.data.code==="200") {
          alert('操作成功: 数据' + res.data.msg);
        } else {
          alert('操作失败: 数据' + res.data.msg);
        }
      });
    },
    handleSelect(key){
      this.currentPage = key
    },
    handleCommand(command){
      if(command === 'e'){
        this.logout()
      }else{
        this.currentPage='个人中心'
      }
    }
  }
}
</script>
